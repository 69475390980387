<template>
  <Layout>
    <div class="base-width">
      <div class="c-card md-auth md-auth-1">
        <div class="title s26 color-2 font-medium">劳务分包身份认证</div>

        <form class="form s16 color-2" @submit.prevent="handleSubmit">

          <div class="form-item form-item--50">
            <label class="form-item_label required">
              <div>
                <p class="p_label">联系人姓名</p>
              </div>
            </label>
            <div class="form-item_content">
              <input type="text" class="ipt" placeholder="请输入联系人姓名" v-model="form.suppliers_name">
            </div>
          </div>

          <div class="form-item form-item--50">
            <label class="form-item_label required">
              <div>
                <p class="p_label">联系人手机号</p>
              </div>
            </label>
            <div class="form-item_content">
              <input type="text" class="ipt" placeholder="请输入联系人手机号" v-model="form.suppliers_phone" disabled>
            </div>
          </div>

          <div class="form-item form-item--50">
            <label class="form-item_label">
              <div>
                <p class="p_label">公司名称</p>
              </div>
            </label>
            <div class="form-item_content">
              <input type="text" class="ipt" placeholder="请输入公司名称" v-model="form.company_name">
            </div>
          </div>

          <div class="form-item form-item--50">
            <label class="form-item_label">
              <div>
                <p class="p_label">纳税人识别号</p>
              </div>
            </label>
            <div class="form-item_content">
              <input type="text" class="ipt" placeholder="请输入纳税人识别号" v-model="form.credit_code">
            </div>
          </div>

          <div class="form-item form-item--50">
            <label class="form-item_label">
              <div>
                <p class="p_label">注册地</p>
              </div>
            </label>
            <div class="form-item_content">
              <input type="text" class="ipt" placeholder="请输入注册地" v-model="form.company_address">
            </div>
          </div>

          <div class="form-item form-item--50">
            <label class="form-item_label required">
              <div>
                <p class="p_label">服务范围</p>
              </div>
            </label>
            <div class="form-item_content">
              <BaseRadio :options='areaArr' v-model="form.area_scope" name='area'></BaseRadio>
            </div>
          </div>

          <div class="form-item">
            <label class="form-item_label required">
              <div>
                <p class="p_label">涉及工种</p>
                <p class="color-6 s14 p_tips">(可多选)</p>
              </div>
            </label>
            <div class="form-item_content">
              <BaseCheckbox :options='workArr' v-model="workType" name='workType' @change="workChange"
                @checkFn="workCheckFn" :key="workKey">
              </BaseCheckbox>
            </div>
          </div>

          <template v-for="(item, index) in fileArr">
            <div class="form-item form-item--50">
              <label class="form-item_label required">
                <div>
                  <p class="p_label">{{ item.name }}</p>
                </div>
              </label>
              <div class="form-item_content" :key="fileKey">
                <BaseFile :placeholder="'请上传' + item.name + '资质'" v-model="item.img" :max='1'
                  @change="fileChange($event, index)" :key="item.id + 'a'"></BaseFile>
              </div>
            </div>

            <div class="form-item form-item--50">
              <label class="form-item_label required">
                <div>
                  <p class="p_label">{{ item.name }}数量</p>
                </div>
              </label>
              <div class="form-item_content">
                <input type="text" class="ipt" :placeholder="'请输入' + item.name + '数量'" v-model="item.user_count">
              </div>
            </div>
          </template>

          <div class="form-item form-item--50 full">
            <label class="form-item_label">
              <div>
                <p class="p_label">营业执照</p>
              </div>
            </label>
            <div class="form-item_content">
              <BaseFile v-model="licenseArr" placeholder="请上传营业执照" :max='1' @change="licenseChange" :key="licenKey">
              </BaseFile>
            </div>
          </div>

          <div class="form-item form-item--idcard">
            <label class="form-item_label required">
              <div>
                <p class="p_label">身份证图片</p>
              </div>
            </label>
            <div class="form-item_content">
              <BaseIdCard type='front' v-model="idcard_1" @change="forntChange" :key="frontKey"></BaseIdCard>
              <BaseIdCard type='back' v-model="idcard_2" @change="backChange" :key="backKey + 'a'"></BaseIdCard>
            </div>
          </div>

          <div class="form-item form-item--50">
            <label class="form-item_label">
              <div>
                <p class="p_label">邀请码</p>
              </div>
            </label>
            <div class="form-item_content">
              <input type="text" class="ipt" placeholder="请输入邀请码" v-model="form.code">
            </div>
          </div>

          <!-- <div class="form-item form-item--agreement">
            <label class="c-radio">
              <input type="checkbox" hidden v-model="agree">
              <div class="c-radio_icon"></div>
            </label>
            申请即表示同意易筑平台<router-link to='/agreement'>《入驻协议》</router-link>
          </div> -->

          <div class="form-item form-item--submit">
            <BaseButton size='large' @click='ok'>提交</BaseButton>
          </div>
        </form>
      </div>
    </div>
  </Layout>
</template>

<script>
import { SettledSubmitApi, SettledReadyApi } from "@/request/api/user";
export default {
  created() {
    SettledReadyApi({ type: 8 }).then(({ status, data }) => {
      if (status == 200) {
        if (data.info) {
          this.form = data.info;
          if (data.info.license) {
            this.licenseArr[0] = data.info.license;
          }
          this.licenKey++;
          if (data.info.id_card_img.length) {
            this.idcard_1 = data.info.id_card_img[0];
          }
          if (data.info.id_card_img.length > 1) {
            this.idcard_2 = data.info.id_card_img[1];
          }
          this.frontKey++;
          this.backKey++;
          this.workType = data.info.work_type.split(",");
          this.workKey++;
          data.info.work_type_info.map(item => {
            this.fileArr.push({
              type: item.id,
              id: item.id,
              name: item.name,
              user_count: item.user_count,
              img: [item.img],
            })
          })
          this.fileKey++;
        }
        data.area_score.map(item => {
          item.value = item.id;
        })
        this.areaArr = data.area_score;
        data.work_type.map(item => {
          this.workArr.push({ value: item.id, name: item.value })
        })
        this.form.suppliers_phone = data.mobile;
      }
    })
  },
  data() {
    return {
      form: {
        type: 8,
        suppliers_name: "",
        suppliers_phone: "",
        company_name: "",
        credit_code: "",
        company_address: "",
        id_card_img: [],
        license: "",
        area_scope: "",
        work_type: "",
        work_type_info: [],
        code: ""
      },
      licenseArr: [],
      licenKey: 0,
      frontKey: 0,
      backKey: 0,
      workKey: 0,
      fileKey: 0,
      agree: true,
      idcard_1: null,
      idcard_2: null,
      areaArr: [],
      workArr: [],
      workType: [],
      fileArr: [],
      area: '',
    };
  },
  methods: {
    workChange(e) {
      this.form.work_type = e.toString();
    },
    workCheckFn(e) {
      this.fileArr = [];
      e.map(item => {
        this.fileArr.push({
          type: item.value,
          id: item.value,
          name: item.name,
          user_count: "",
          img: []
        })
      })
    },
    fileChange(e, index) {
      this.fileArr[index].img = e;
    },
    // 营业执照改变
    licenseChange(arr) {
      this.form.license = arr[0];
      this.licenseArr = arr;
    },
    // 身份证正面
    forntChange(str) {
      this.form.id_card_img[0] = str;
    },
    // 身份证反面
    backChange(str) {
      this.form.id_card_img[1] = str;
    },
    ok() {
      this.form.work_type_info = JSON.parse(JSON.stringify(this.fileArr));
      this.form.work_type_info.map(item => {
        item.img = item.img.toString();
      })
      let obj = JSON.parse(JSON.stringify(this.form))
      obj.id_card_img = obj.id_card_img.toString();
      obj.work_type_info = JSON.stringify(obj.work_type_info);
      SettledSubmitApi(obj).then(({ status, data, msg }) => {
        if (status == 200) {
          this.$message.success(msg);
          this.$router.push(`/join`);
        }
      })

    },
    handleSubmit() {

    },
  }
};
</script>

<style lang="scss" scoped></style>